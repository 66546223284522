<template>
  <!-- <common-header :title="title"></common-header> -->
  <div class="header q-pl-sm">
    <q-icon name="west" @click="goBack" style="font-size: 25px" />
    <span class="title q-pl-md">{{ $tc('eel_document') }}</span>
  </div>

  <div class="total_finish">
    <div class="q-py-md text-center text-white custom_font">
      <span>{{ $tc('total_finish') }}</span>
      <br />
      <span>{{ total_finish_percent }}%</span>
    </div>
    <div class="q-pt-sm q-pb-md q-px-lg finish_progress">
      <div>
        <span class="text-white float-right">{{ total_finish_percent }}%</span>
        <q-linear-progress rounded white size="8px" :value="total_finish_percent / 100" />
      </div>
    </div>
  </div>
  <div class="q-pa-md">
    <div class="q-gutter-md">
      <div>
        <span class="text-negative q-pr-md">*</span>
        <span>{{ $tc('fullfill_data') }}</span>
      </div>
      <!-- loan_id: {{ this.loan_id }}
            <br />
            token: {{ this.token }} -->
      <div class="spacing row items-start">
        <q-card class="my_card q-mb-md"
          :style="{ opacity: (formPermissions?.permissions?.applicant_information?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.applicant_information)) ? 0.6 : 1 }">
          <q-card-section @click="toApplicantInformation"
            :style="{ cursor: (formPermissions?.permissions?.applicant_information?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.applicant_information)) ? 'not-allowed' : 'default' }">
            <div>
              <img class="image" src="../../src/assets/Applicant_Information.jpg" />
            </div>
            <div class="text-center">
              <span class="text-green">{{ eelLoan.applicant_information_percent }}%</span>
              <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px"
                :value="eelLoan.applicant_information_percent / 100" />
              <span class="custom_size">{{ $tc('applicant_information') }}</span>
            </div>

            <!-- <div class="text-center" v-else>
                        <span class="text-green">0%</span>
                        <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px" :value="0" />
                        <span class="custom_size">Applicant Information</span>
                     </div> -->
          </q-card-section>
        </q-card>

        <q-card class="my_card q-mb-md"
          :style="{ opacity: (formPermissions?.permissions?.occupation_information?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.occupation_information)) ? 0.6 : 1 }">
          <q-card-section @click="toOccupationInformation"
            :style="{ cursor: (formPermissions?.permissions?.occupation_information?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.occupation_information)) ? 'not-allowed' : 'default' }">
            <div>
              <img class="image" src="../../src/assets/Occupation_Information.jpg" />
            </div>
            <div class="text-center">
              <span class="text-green">{{ eelLoan.applicant_occupation_percent }}%</span>
              <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px"
                :value="eelLoan.applicant_occupation_percent / 100" />
              <span class="custom_size">{{ $tc('occupation_information') }}</span>
            </div>

            <!-- <div class="text-center" v-else>
                        <span class="text-green">0%</span>
                        <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px" :value="0" />
                        <span class="custom_size">Occupation Information</span>
                     </div> -->
          </q-card-section>
        </q-card>

        <q-card class="my_card q-mb-md"
          :style="{ opacity: (formPermissions?.permissions?.guarantor_family?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.guarantor_family)) ? 0.6 : 1 }">
          <q-card-section @click="toGuarantorFamily"
            :style="{ cursor: (formPermissions?.permissions?.guarantor_family?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.guarantor_family)) ? 'not-allowed' : 'default' }">
            <div>
              <img class="image" src="../../src/assets/Gurantar_family.jpg" />
            </div>
            <div class="text-center">
              <span class="text-green">{{ eelLoan.guarantor_family_percent }}%</span>
              <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px"
                :value="eelLoan.guarantor_family_percent / 100" />
              <span class="custom_size">{{ $tc('guarantor1_information') }}</span>
            </div>

            <!-- <div class="text-center" v-else>
                        <span class="text-green">0%</span>
                        <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px" :value="0" />
                        <span class="custom_size">Guarantor_Family</span>
                     </div> -->
          </q-card-section>
        </q-card>

        <q-card class="my_card q-mb-md"
          :style="{ opacity: (formPermissions?.permissions?.guarantor_colleague?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.guarantor_colleague)) ? 0.6 : 1 }">
          <q-card-section @click="toGuarantorColleague"
            :style="{ cursor: (formPermissions?.permissions?.guarantor_colleague?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.guarantor_colleague)) ? 'not-allowed' : 'default' }">
            <div>
              <img class="image" src="../../src/assets/gurantar.jpg" />
            </div>
            <div class="text-center">
              <span class="text-green">{{ eelLoan.guarantor_colleague_percent }}%</span>
              <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px"
                :value="eelLoan.guarantor_colleague_percent / 100" />
              <span class="custom_size">{{ $tc('guarantor2_information') }}</span>
            </div>

            <!-- <div class="text-center" v-else>
                        <span class="text-green">0%</span>
                        <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px" :value="0" />
                        <span class="custom_size">Guarantor_Colleague</span>
                     </div> -->
          </q-card-section>
        </q-card>

        <q-card class="my_card q-mb-md"
          :style="{ opacity: (formPermissions?.permissions?.bu_document_upload?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.bu_document_upload)) ? 0.6 : 1 }">
          <q-card-section @click="toUploadDocument"
            :style="{ cursor: (formPermissions?.permissions?.bu_document_upload?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.bu_document_upload)) ? 'not-allowed' : 'default' }">
            <div>
              <img class="image" src="../../src/assets/Docunment_upload.jpg" />
            </div>
            <div class="text-center">
              <span class="text-green">{{ eelLoan.application_document_percent }}%</span>
              <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px"
                :value="eelLoan.application_document_percent / 100" />
              <span class="custom_size">{{ $tc('upload_document') }}</span>
            </div>

            <!-- <div class="text-center" v-else>
                        <span class="text-green">0%</span>
                        <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px" :value="0" />
                        <span class="custom_size">Upload Document</span>
                     </div> -->
          </q-card-section>
        </q-card>
      </div>

      <div>
        <q-checkbox v-model="check_agree" label="" class="custom_checkbox" />
        <span v-html="$tc('accept_teams_and_conditions')">
        </span>
      </div>

      <div>
        <q-btn @click="apply" class="button_color" text-color="black" :label="$tc('apply')" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Notify, Loading } from "quasar";
// import CommonHeader from "@/components/Shared/CommonHeader.vue"
export default {
  components: {
    // CommonHeader
  },
  data() {
    return {
      // title: "HP Document",
      total_finish_percent: 0,
      check_agree: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      loan_id: this.$route.query.loan_id,
      token: this.$route.query.token,
      language: this.$route.query.language,
      eelLoan: {},
      disabled_route: false,
      formPermissions: [],
      loan_stage: '',
      form_permission_id: '',
    };
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "BackHome",
        query: {
          loan_id: this.loan_id,
          token: this.token,
          language: this.language,
        },
      });
    },
    changeLanguage(language) {
      this.$i18n.locale = language;
    },
    async getFormPermissions() {
      Loading.show();
      axios
        .get(`${this.baseUrl}v1/loans/${this.loan_id}/form-permissions`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          if (response.data.data != null) {
            this.formPermissions = response.data.data;
            console.log('Permissions', this.formPermissions);
            this.form_permission_id = response.data.data.id;
          }
          if (this.$store.getters["fetchOdooApi/getStateList"].length != 0 &&
            this.$store.getters["fetchOdooApi/getCityList"].length != 0 &&
            this.$store.getters["fetchOdooApi/getTownshipList"].length != 0) {
            Loading.hide();
          }
          this.getEELLoan()
          // Loading.hide();
        })
        .catch((error) => {
          Loading.hide();
          Notify.create({
            type: "negative",
            position: "top",
            message: error.message,
            timeout: 1500,
          });
        });
    },
    async getEELLoan() {
      Loading.show()
      await axios
        .get(`${this.baseUrl}v1/eel-loans/${this.loan_id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.eelLoan = response.data.data;
          this.loan_stage = response.data.data.loan_stage
          this.total_finish_percent =
            (this.eelLoan.applicant_information_percent +
              this.eelLoan.applicant_occupation_percent +
              this.eelLoan.guarantor_family_percent +
              this.eelLoan.guarantor_colleague_percent +
              this.eelLoan.application_document_percent) /
            5;
          Loading.hide();
        })
        .catch((error) => {
          Loading.hide();
          Notify.create({
            type: "negative",
            message: error.message,
            position: "top",
            timeout: 1500,
          });
        });
    },
    toApplicantInformation() {
      if (this.formPermissions?.permissions?.applicant_information?.length == 0 || (this.loan_stage == 'document_applied_by_customer' && !this.formPermissions?.permissions?.applicant_information)) {
        return
      } else if (this.disabled_route == false) {
        this.$store.commit("applicant/updateLoanID", this.loan_id);
        this.$router.push({
          name: "ApplicantInformation",
          query: {
            loan_id: this.loan_id,
            token: this.token,
            language: this.language,
            applicant_information_url: this.eelLoan.applicant_information_url,
          },
        });
      } else {
        Notify.create({
          type: "negative",
          position: "top",
          message: "You can't go this page when applying process!",
          timeout: 1500,
        });
      }
    },
    toOccupationInformation() {
      if (this.formPermissions?.permissions?.occupation_information?.length == 0 || (this.loan_stage == 'document_applied_by_customer' && !this.formPermissions?.permissions?.occupation_information)) {
        return
      } else if (this.disabled_route == false) {
        this.$store.commit("occupation/updateLoanID", this.loan_id);
        this.$router.push({
          name: "OccupationInformation",
          query: {
            loan_id: this.loan_id,
            token: this.token,
            language: this.language,
            applicant_occupation_url: this.eelLoan.applicant_occupation_url,
          },
        });
      } else {
        Notify.create({
          type: "negative",
          position: "top",
          message: "You can't go this page when applying process!",
          timeout: 1500,
        });
      }
    },
    toGuarantorFamily() {
      if (this.formPermissions?.permissions?.guarantor_family?.length == 0 || (this.loan_stage == 'document_applied_by_customer' && !this.formPermissions?.permissions?.guarantor_family)) {
        return
      } else if (this.disabled_route == false) {
        this.$store.commit("family/updateLoanID", this.loan_id);
        this.$router.push({
          name: "GuarantorFamily",
          query: {
            loan_id: this.loan_id,
            token: this.token,
            language: this.language,
            guarantor_family_url: this.eelLoan.guarantor_family_url,
          },
        });
      } else {
        Notify.create({
          type: "negative",
          position: "top",
          message: "You can't go this page when applying process!",
          timeout: 1500,
        });
      }
    },
    toGuarantorColleague() {
      if (this.formPermissions?.permissions?.guarantor_colleague?.length == 0 || (this.loan_stage == 'document_applied_by_customer' && !this.formPermissions?.permissions?.guarantor_colleague)) {
        return
      } else if (this.disabled_route == false) {
        this.$store.commit("colleague/updateLoanID", this.loan_id);
        this.$router.push({
          name: "GuarantorColleague",
          query: {
            loan_id: this.loan_id,
            token: this.token,
            language: this.language,
            guarantor_colleague_url: this.eelLoan.guarantor_colleague_url,
          },
        });
      } else {
        Notify.create({
          type: "negative",
          position: "top",
          message: "You can't go this page when applying process!",
          timeout: 1500,
        });
      }
    },
    toUploadDocument() {
      if (this.formPermissions?.permissions?.bu_document_upload?.length == 0 || (this.loan_stage == 'document_applied_by_customer' && !this.formPermissions?.permissions?.bu_document_upload)) {
        return
      } else if (this.disabled_route == false) {
        this.$store.commit("upload/updateLoanID", this.loan_id);
        this.$router.push({
          name: "UploadDocument",
          query: {
            loan_id: this.loan_id,
            token: this.token,
            language: this.language,
            application_document_url: this.eelLoan.application_document_url,
          },
        });
      } else {
        Notify.create({
          type: "negative",
          position: "top",
          message: "You can't go this page when applying process!",
          timeout: 1500,
        });
      }
    },
    apply() {
      if (this.total_finish_percent != 100) {
        Notify.create({
          type: "negative",
          position: "top",
          message: this.language == 'my' ? 'အချက်အလက်များကို ၁၀၀ ရာခိုင်နှုန်း ပြည့်အောင် ဖြည့်ရန် လိုအပ်ပါသည်။' : "Please fill the all data to complete 100%!",
          timeout: 1500,
        });
      } else {
        if (this.check_agree == true) {
          let parameters = {
            stage: "document_applied_by_customer",
            form_permission_id: this.form_permission_id ?? ''
          };
          Loading.show();
          axios
            .put(`${this.baseUrl}v1/eel-loans/${this.loan_id}`, parameters, {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            })
            .then((response) => {
              this.disabled_route = true;
              this.$router.push({
                name: "SaveApplyInformation",
                query: {
                  loan_id: this.loan_id,
                  token: this.token,
                  language: this.language,
                },
              });
              Notify.create({
                type: "positive",
                position: "top",
                message: this.language =='my' ? 'စာရွက်စာတမ်း တင်သွင်းမှု အောင်မြင်ပါသည်။' : response.data.message,
                timeout: 1500,
              });
              Loading.hide();
            })
            .catch((error) => {
              Loading.hide();
              Notify.create({
                type: "negative",
                position: "top",
                message: error.message,
                timeout: 1500,
              });
            });
        } else {
          Notify.create({
            type: "negative",
            position: "top",
            message: this.language == 'my' ? '  Win Finance စည်းကမ်းနှင့်သတ်မှတ်ချက်များကို လိုက်နာရပါမည်။' : "You must agree Win Finance Terms & Condition" ,
            timeout: 1500,
          });
        }
      }
    },
  },
  created() {
    this.getFormPermissions();
    this.changeLanguage(this.language);
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 55px;
  position: fixed;
  z-index: 1;
  padding-top: 17px;
  background: linear-gradient(120.26deg, #f4d9a9 35.44%, #cbcbbc 75.7%);
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.total_finish {
  padding-top: 55px;
  background-color: #005198;
}

.custom_font {
  font-size: 16px;
}

.finish_progress {
  background-color: rgba(255, 255, 255, 0.15);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.q-linear-progress {
  color: #008000;
  background: #fff;
}

.my_card {
  width: 47%;
  border-radius: 20px;
}

.spacing {
  justify-content: space-between !important;
}

.q-card__section--vert {
  padding: 10px !important;
}

.image {
  width: 100%;
}

.custom_size {
  font-size: 12px;
}

.mini_progress {
  width: 75%;
  margin: 2% 13%;
}

.custom_checkbox {
  margin-left: -10px;
}

.button_color {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  margin-bottom: 60px;
  /* margin-bottom: 120px; */
  background: linear-gradient(120.26deg, #f4d9a9 35.44%, #cbcbbc 75.7%);
}
</style>